/* big calendar */

.rbc-header {
  background-color: #0057ba;
  border-bottom: 1px solid #ddd;
  padding: 10 !important;
  font-weight: bold;
  font-size: 90%;
  color: white;
}

.rbc-event {
  padding: 0px !important;
  border-radius: 0px !important;
  border: none !important;
  background-color: transparent !important;
}
.rbc-time-slot {
  /* background-color: #eaf3fa !important; */
  /* color: ; */
}

.rbc-event-label {
  display: none !important;
}

.rbc-events-container {
  width: 100%;
}
.rbc-today.rbc-header {
  background-color: #626a71 !important;
}
.rbc-button-link {
  padding-bottom: 10px;
}

.rbc-timeslot-group {
  min-height: 410px !important;
}
.rbc-btn-group {
  display: none !important;
}
.rbc-button-link {
  pointer-events: none;
}
.rbc-event {
  width: 100% !important;
  left: 0% !important;
  min-height: 80px !important;
}
