@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fcfcfc !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.scrollbar-y::-webkit-scrollbar {
  width: 10px;
}

.scrollbar-y::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 6px;
}

.scrollbar-y::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* big calendar */

.rbc-header {
  background-color: #0057ba;
  border-bottom: 1px solid #ddd;
  padding: 10 !important;
  font-weight: bold;
  font-size: 90%;
  color: white;
}

.rbc-event {
  padding: 0px !important;
  border-radius: 0px !important;
  border: none !important;
  background-color: transparent !important;
}
.rbc-time-slot {
  /* background-color: #eaf3fa !important; */
  /* color: ; */
}

.rbc-event-label {
  display: none !important;
}

.rbc-events-container {
  width: 100%;
}
.rbc-today.rbc-header {
  background-color: #626a71 !important;
}
.rbc-button-link {
  padding-bottom: 10px;
}

.rbc-timeslot-group {
  min-height: 110px !important;
}
.rbc-btn-group {
  display: none !important;
}
.rbc-button-link {
  pointer-events: none;
}
.rbc-toolbar-label {
  display: none !important;
}

@media (max-width: 768px) {
  
  .responsive-paper {
      max-width: 200px; 
  }
}

@media (min-width: 769px) and (max-width: 1200px) {
  
  .responsive-paper {
      max-width: 250px; 
  }
}
