@import url("https://fonts.googleapis.com/css2?family=Genos:wght@200;300;400&family=Raleway:wght@100;200;300;400;500;600;700&display=swap");
body {
  font-family: "Raleway", sans-serif !important;
  margin: 0 !important;
}
.error {
  color: red;
  margin-top: 0.5rem;
}
.custom-input::placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-weight: lighter;
}
.logo-home-right {
  position: absolute;
  top: -300px;
  right: -200px;
  height: 700px;
}
.logo-home-left {
  position: absolute;
  bottom: -300px;
  left: -250px;
  height: 700px;
  overflow: hidden;
  z-index: 0;
}
@media (max-width: 1000px) {
  .logo-home-right,
  .logo-home-left {
    display: none;
  }
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}
.MuiDataGrid-toolbarContainer button {
  color: black;
}
.highlighted-row {
  background-color: yellow;
}
.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.watermark {
  position: absolute;
  font-weight: bold !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
  color: white;
  font-size: 20px !important;
  padding: 2px;
  background: rgba(0, 87, 186, 0.4);
  display: flex;
  justify-content: center; /* Centre horizontalement */
  align-items: center;
  white-space: nowrap;
  width: 100%;
  height: 100%;
}

.crop-container {
  width: 100%;
  height: 600px;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 80px;
  border-radius: 20px;
  background-color: #d0cfcf;
}

.controls {
  position: relative; /* Change absolute à relative */
  width: 100%;
  height: 10px;
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.slider {
  padding: 22px 0px;
}

.zoom-range {
  -webkit-appearance: none;
  -moz-appearance: none;
  height: 2px;
  border-top: 5px solid #d0cfcf;
  border-bottom: 5px solid #d0cfcf;
  background: #3f51b5;
  width: 100%;
  border-radius: 20px;
}

.zoom-range::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.reactEasyCrop_Container {
  border-radius: 20px;
}

.zoom-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #3f51b5;
  background: #3f51b5;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  transition: box-shadow 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.controls:hover input[type="range"]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
  border-radius: 50%;
}

.controls:hover input[type="range"]::-moz-range-thumb {
  box-shadow: 0px 0px 0px 8px rgba(63, 81, 181, 0.16);
}

.container-ratio {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: gray;
}

.box-ratio {
  position: relative;
  flex: 1;
  background-color: white;
}

.content-ratio {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfile + label {
  color: white;
  background-color: #0057ba;
  display: inline-block;
  cursor: pointer;
  font-size: 16px;
  padding: 10px;
  border-radius: 10px;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(
    255,
    255,
    255,
    0.8
  ); /* Fond semi-transparent pour empêcher les clics */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9998;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*.app.loading {*/
/*  !* Ajoutez des styles pour désactiver les clics sur le reste de l'application *!*/
/*  pointer-events: none;*/
/*  opacity: 0.7;*/
/*}*/
